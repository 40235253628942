.offer-card-custom {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  /* position: relative; */
}
.offer-card {
  padding: 15px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-self: space-between;
  align-items: center;
  z-index: 50 !important;
}
.offer-card .offer-description {
  font-size: 14px;
  padding: 5px 0px;
}

@media only screen and (min-width: 1200px) {
  .offer-card-custom {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 1199px) {
  .offer-card-custom {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* margin: 0 1.5rem; */
  }
}
@media only screen and (max-width: 900px) {
  .offer-card-custom {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* margin: 0 1.5rem; */
  }
}
/* .circle {
  z-index: 20;
} */
.circles {
  position: absolute;
  top: -85px;
  left: 0;
  bottom: 170px;
  width: -webkit-fill-available;
  height: 700px;
  overflow: hidden;
  /* z-index: 20; */
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.circles :nth-child(even) {
  position: absolute;
  height: auto;
  display: block;
  color: rgba(255, 255, 255, 0.2);
  animation: animate 5s infinite linear;
  /* bottom: 100px; */
}
.circles :nth-child(odd) {
  position: absolute;
  height: auto;
  display: block;
  color: rgba(255, 255, 255, 0.2);
  animation: animate2 5s infinite linear;
  /* bottom: 100px; */
}
.circles :nth-child(1) {
  top: 0%;
  left: 0%;
  width: 80px !important;
  animation: animate 5s infinite linear;
}

.circles :nth-child(2) {
  left: 20%;
  top: 77%;
  width: 90px !important;
}

.circles :nth-child(3) {
  top: 0%;
  right: 20%;
  width: 80px !important;
}

.circles :nth-child(4) {
  top: 75%;
  right: 6%;
  width: 130px !important;
}

.circles :nth-child(5) {
  top: 45%;
  right: 0%;
  width: 110px !important;
  animation: animate 5s infinite linear;
}

.circles :nth-child(6) {
  top: 9%;
  left: 94%;
}
.circles :nth-child(7) {
  top: 50%;
  left: 1%;
}
