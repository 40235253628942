/*----------------  ----------------------------------------------
# AboutCompany  Style
--------------------------------------------------------------*/
/* .main {
  z-index: 10;
  margin-top: -15%;
} */
.about {
  position: relative;
  /* margin-top: -20%; */
}
.about-png {
  position: absolute;
  right: -40px;
  top: 60px;
  z-index: 3;
}
.about-png2 {
  position: absolute;
  left: 25px;
  top: -20px;
  z-index: 2;
}
.about-png3 {
  position: absolute;
  left: 70px;
  top: 150px;
  z-index: 1;
}
.brain-one {
  position: absolute;
  top: 120px;
  left: -180px;
}
.brain-two {
  position: absolute;
  top: 350px;
  left: -150px;
}
.brain-three {
  position: absolute;
  top: -40px;
  right: -100px;
}
.brain-four {
  height: auto;
  width: 200px;
  position: absolute;
  top: 200px;
  right: -250px;
}

@media only screen and (min-width: 1200px) {
  .about-tab-btn {
    padding: 0.5rem 2.6rem !important ;
  }
}
@media only screen and (max-width: 991px) {
  .about {
    height: 550px;
    margin-top: 5%;
    display: grid;
    place-items: center;
  }
  .about img {
    height: auto;
    /* width: 40%; */
  }
  .about-png {
    left: 300px;
  }
  .about-png2 {
    left: 70px;
    top: -30px;
  }
  .about-png3 {
    left: 180px;
    top: 80px;
  }
  .brain-one {
    position: absolute;
    top: 25px;
    left: 500px !important;
  }
  .brain-two {
    position: absolute;
    top: 450px;
    left: -25px;
  }

  .brain-three {
    width: 50px !important;
    height: 40px !important;
    position: absolute;
    top: -120px;
    right: 70px;
  }
  .brain-four {
    position: absolute;
    top: 120px;
    right: -150px;
  }
  .about-tab-wrapper {
    padding: 0 10%;
  }
}
@media only screen and (max-width: 767px) {
  .about-tab-wrapper {
    text-align: center !important;
    margin: 0 1.5rem;
    padding: 0 2%;
  }
  .about-tab-wrapper .about-tabs {
    display: flex;
    justify-content: center;
  }
  .about {
    height: 390px;
    margin-top: 10%;
    display: grid;
    place-items: center;
  }
  .about img {
    height: auto;
    width: 50%;
  }
  .about-png {
    left: 150px;
  }
  .about-png2 {
    left: 75px;
    top: 5px;
  }
  .about-png3 {
    left: 100px;
    top: 130px;
  }
  .brain-one {
    position: absolute;
    top: 30px;
    left: 350px !important;
  }
  .brain-two {
    display: none;
  }
  .brain-three {
    width: 50px !important;
    height: 40px !important;
    position: absolute;
    top: -70px;
    right: 15px;
  }
  .about-tab-btn {
    padding: 0.5rem 1rem !important;
  }
}

.about-tab-wrapper div {
  overflow: visible !important;
  /* height: 100%; */
  /* margin-top: -5%; */
}
.about-tab-btn {
  /* padding: 14px 30px; */
  text-align: center;
  cursor: pointer;
  transition: all 0.2s linear;
  padding: 0.5rem 2.8rem;
  font-size: 16px !important;
  font-weight: 600 !important;
  overflow: visible !important;
  color: #000;
}
.about-tab-btn.Mui-selected {
  background: rgb(108, 197, 255) !important;
  color: #fff !important;
  /* border-bottom: 10px solid rgb(108, 197, 255) !important; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}
.about-tab-btn.Mui-selected::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 10px solid rgb(108, 197, 255) !important;
  /* border-bottom: 10px solid rgb(108, 197, 255) !important; */
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
