.management-team {
  padding: 64px 0;
}
.btn-wrapper .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  border-color: #fff !important;
  color: #fff !important;
}

.btn-wrapper {
  display: flex;
  gap: 60px;
}
@media only screen and (max-width: 768px) {
  .management-team {
    text-align: center;
  }
  .btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .btn-wrapper button:last-child {
    margin-top: 1rem;
  }
  .btn-wrapper button {
    width: 100%;
  }
  .management-feature-title {
    padding: 5rem 0;
    padding-top: 88px !important;
  }
}

.management-feature-title {
  text-align: center;
  background-image: url(../../image/management/text-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  align-items: center;
  display: grid;
}
@media only screen and (max-width: 991px) {
  .management-feature-title {
    height: 260px;
  }
}
