.slider {
  flex-grow: 1;
  margin: 64px 0;
}
.certificate-carousel {
  height: 580px !important;
  position: relative !important;
}
.certificate-wrapper {
  padding-top: 50px;
}
.slider-images-boy {
  padding: 0px 0px 0px 35px;
  position: absolute;
  top: -45px;
}
.img-boy {
  width: auto;
  height: 720px;
}
.certificate-carousel div:nth-child(2) button,
.certificate-carousel div:nth-child(3) button {
  color: #ffffff !important;
  background-color: #f47621 !important;
  border: 1px solid #f47621 !important;
}
.certificate-carousel div:nth-child(2) button svg,
.certificate-carousel div:nth-child(3) button svg {
  width: 2em;
  height: 2em;
}
.certificate-carousel div:nth-child(4) button,
.certificate-carousel div:nth-child(5) button {
  display: none !important;
}
.certificate-wrapper h3:first-child {
  font-size: 30px !important;
  margin-bottom: 10px !important;
}
.certificate-wrapper .description {
  margin: 10px 0 !important;
}
@media only screen and (max-width: 991px) {
  .slider {
    flex-grow: 1;
    margin: 0;
  }
  .certificate-carousel {
    height: 520px !important;
    padding-left: 65px;
    padding-right: 65px;
    text-align: center;
  }
  .certificate-carousel :nth-child(2) {
    right: 10px !important;
  }
  .certificate-carousel :nth-child(3) {
    left: 10px !important;
  }
  .certificate-carousel div:nth-child(2) button svg,
  .certificate-carousel div:nth-child(3) button svg {
    width: 1em;
    height: 1em;
  }
  .certificate-wrapper {
    padding: 0 1.5rem;
  }
  .slider-card {
    display: flex;
    justify-content: center;
  }
  .slider-images-boy {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .slider {
    flex-grow: 1;
    margin: 0;
  }
  .certificate-carousel {
    height: 620px !important;
    padding-left: 45px;
  }
  /* .certificate-carousel :nth-child(1) {
    margin-left: 2px;
  } */
  .certificate-carousel :nth-child(2) {
    top: -25px !important;
    right: -5px !important;
  }
  .certificate-carousel :nth-child(3) {
    top: -25px !important;
    left: 0 !important;
  }
  .certificate-carousel div:nth-child(2) button,
  .certificate-carousel div:nth-child(3) button {
    margin-top: -5rem;
  }

  .custom-slider-mobile {
    text-align: center;
  }
  .slider-card {
    justify-content: center !important;
  }
  .slider-images-boy {
    display: none;
  }
  .certificate-carousel div:nth-child(2) button,
  .certificate-carousel div:nth-child(3) button {
    color: #ffffff !important;
    background-color: #ffbb7e !important;
    border: 1px solid #ffbb7e !important;
    opacity: 0.8;
  }
  .certificate-wrapper {
    padding: 0 0.8rem;
  }
}
@media only screen and (max-width: 450px) {
  .certificate-carousel {
    height: 700px !important;
    padding-left: 65px;
  }
}
