.MuiAppBar-root {
  /* margin: 0 -24px !important; */
  /* Replace by theme.spacing or actual padding values */
  /* width: calc(100% + 48px) !important; */
  /* 100% + marginX */
}
@keyframes header {
  /* 0% {
    background-color: transparent;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: initial;
    /* transform: translateY(50px); */
  /* } */
  from {
    height: 115px;
    padding: 15px 40px 50px 40px;
  }
  to {
    height: 85px;
    padding: 5px 40px 20px 40px;
    /* display: flex;
    align-items: center; */
  }
}
.header-transparent {
  background-color: transparent !important;
  height: 110px;
  color: #000;
  padding: 25px 40px 30px 40px;
  box-shadow: none !important;
}
.header-solid {
  background-color: rgb(255, 255, 255, 0.93) !important;
  color: #000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  /* transition: all 1s ease-in-out; */
  animation: header 0.4s ease-in-out;
  animation-fill-mode: forwards;
}
.header-logo {
  width: auto;
  height: 75px;
  margin-right: 55px;
  transition: all 0.4s ease-in-out;
}
.header-transit {
  width: auto;
  height: 70px;
  padding-top: 10px;
  margin-right: 45px;
  transition: all 0.4s ease-in-out;
}
.header-social-icons {
  height: 1.5rem;
  /* width: 4rem; */
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: #f993d2;
  margin: 10px 0;
}
.header-social-icons a {
  position: relative;
  margin: 0 auto;
  /* height: 1rem; */
  /* padding: 0 1em; */
  overflow: hidden;
  cursor: pointer;
}
.header-social-icons span {
  position: relative;
  display: inline-block;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  color: #f993d2;
}

.header-social-icons a:hover span {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.header-social-icons :nth-child(1) span::before {
  position: absolute;
  top: 100%;
  content: url(../../image/icons/twitter.png);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  color: #f993d2;
}
.header-social-icons :nth-child(2) span::before {
  position: absolute;
  top: 100%;
  content: url(../../image/icons/linkedin.png);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  color: #f993d2;
}
.header-social-icons :nth-child(3) span::before {
  position: absolute;
  top: 100%;
  content: url(../../image/icons/youtube.png);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  color: #f993d2;
}
/* .header-social-icons a:hover {
  color: aqua !important;
} */

.header-btn-1 {
  background-color: #fff !important;
  padding: 14px 10px !important;
  margin: 12px !important;
  color: #000 !important;
}

.mobile-navbar {
  display: none;
}
.mobile-menu-icon {
  color: #000 !important;
}
.mobile-overly {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.mobil-nav-item ul {
  display: inline-grid;
  width: 100%;
  padding: 0px !important;
}
.mobile-nav-link {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px;
  color: rgb(52, 71, 103);
  text-decoration: none;
  padding: 10px 15px;
  display: flex;
  width: auto;
  align-items: center;
}
.mobile-nav-link:hover {
  color: rgb(52, 71, 103);
  background: #389ff940;
}
.mobil-nav-item div:nth-child(3) {
  width: 100%;
  left: 1rem !important;
  top: 5.5rem !important;
}
.header-nav-items {
  display: flex;
  flex-grow: 1;
  /* justify-content: space-evenly; */
  justify-content: center;
  align-items: flex-end;
  gap: 30px;
  width: 80%;
  padding-top: 15px;
}
@media only screen and (max-width: 1330px) {
  /* .header-nav-items {
    max-width: min-content;
    margin-left: 4rem;
    gap: 50px;
  } */
}

@media only screen and (max-width: 950px) {
  .mobile-navbar {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  .header-nav-items {
    display: none;
  }
  .header-solid,
  .header-transparent {
    padding: 30px 60px;
  }
}

@media only screen and (max-width: 768px) {
  .header-logo {
    width: 160px;
    height: 75px;
  }
  .header-solid,
  .header-transparent {
    padding: 20px 25px;
  }
}
