.recharts-tooltip-wrapper {
  border: none !important;
  outline: none !important;
  font-size: 13px;
  font-weight: 400;
}
.custom-tooltip {
  font-size: 13px;
  font-weight: 400;
  background-color: #f1f5f7eb;
  color: rgb(123, 128, 154);
  padding: 5px 10px;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}
.recharts-responsive-container {
  font-size: 13px;
  font-weight: 400;
}
