/*  */
.home {
  background-image: url("../../../image/hero-bg.png");
  background-size: cover;
  background-repeat: round;
  /* padding: 12rem 0 5rem 0 !important; */
  height: 1050px;
}
.home-box {
  margin-top: 120px;
}
.custom-container {
  max-width: 1060px !important;
}
.section-padding {
  padding: 30px 0 !important;
}

@media only screen and (max-width: 991px) {
  .home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    text-align: center;
  }
  .custom-container {
    max-height: 800px !important;
  }
  .classes-card-grid {
    padding-left: 3rem !important;
    padding-right: 2rem !important;
    /* margin: 0 2rem; */
  }
  .classes-card {
    width: 100% !important;
  }
}

.classes-card {
  /* height: 80%; */
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 16px 25px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  /* align-items: center; */
  /* gap: 2; */
}
.classes-card h3 {
  text-align: center;
  margin-bottom: 5px !important;
}

.classes-grade-table {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0;
}
.classes-grade-card {
  padding: 8px;
  cursor: pointer;
  display: grid;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #c4c4c4;
}
@media only screen and (max-width: 768px) {
  .home-box {
    margin-top: 60px !important;
  }
  .custom-container {
    max-height: 750px !important;
  }
  .hero-title {
    font-size: 35px !important;
  }
  .classes-grade-card {
    padding: 5px;
  }

  .classes-grade-card .description {
    font-size: 0.8rem;
  }

  .section {
    padding: 30px 0;
    display: grid;
    place-items: center;
  }
  .home {
    background-image: url("../../../image/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    padding: 5rem 0 5rem 0 !important;
  }
}
.classes-grade-card span {
  font-size: 12px !important;
}

.classes-grade-card:hover,
.classes-grade-card.active {
  background: #ff8d1a;
  border: 1px solid #ff8d1a;
  transition: all ease-in-out 0.4s;
}
.classes-grade-card:hover p,
.classes-grade-card.active p {
  color: #fff !important;
}
