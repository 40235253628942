.footer {
  background-image: url(./footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30px 10px;
}
@media only screen and (max-width: 991px) {
  .footer-container {
    width: fit-content !important;
  }
}
@media only screen and (max-width: 768px) {
  .footer {
    padding: 30px 0;
  }
  .footer-link-list {
    padding-left: 1rem !important;
    /* text-align: center !important; */
  }
  .footer-container {
    width: fit-content !important;
  }
}
.footer-logo-img {
  margin-bottom: 1rem;
}
.footer-description {
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.footer-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-bottom: 1.8rem !important;
  color: rgb(255, 255, 255);
}
.footer-link-list {
  text-align: left;
  padding-top: 4rem !important;
  padding-left: 4rem;
}
.footer-link-list p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  transition: all ease-in-out 0.4s;
}
.footer-link-list p:hover {
  transform: translateX(15px);
}
.copyright-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #053f55;
  color: #fff;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.copyright-section h3 {
  margin: 0px;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
}
