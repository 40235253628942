/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
}
.section-bg {
  background: rgb(51, 111, 148);
  background: radial-gradient(
    circle,
    rgba(51, 111, 148, 1) 0%,
    rgb(8, 53, 89) 100%
  );
}
.highlight-section {
  background-color: #f5f5f5;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #000;
}
.section-bg .section-title h2 {
  color: #ffffff;
}
.highlight-section .section-title h2 {
  color: #344767;
  font-size: 40px;
}
.highlight-section .section-title h2::before,
.highlight-section .section-title h2::after {
  /* content: "★"; */
  content: "";
  width: 200px;
  height: 2px;
  background: #344767;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .highlight-section .section-title h2 {
    font-size: 32px;
  }
  .highlight-section .section-title h2::before,
  .highlight-section .section-title h2::after {
    width: 50px;
  }
}

.highlight-section .section-title h2::before {
  margin: 0 15px 10px 0;
}

.highlight-section .section-title h2::after {
  margin: 0 0 10px 15px;
}
.custom-card {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: 1px solid #ccc;
}
/* datatable css */
.custom-datatable {
  padding: 10px !important;
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important;
  border: 1px solid #ccc !important;
  font-family: "Poppins", sans-serif;
}
.custom-datatable h6 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  margin: 0px !important;
  color: rgb(52, 71, 103);
}
.custom-datatable .tss-tlx3x1-MUIDataTableToolbar-root {
  border-bottom: 1px solid #0000001f;
}
.custom-datatable .tss-1dqj55d-MUIDataTableToolbarSelect-root {
  box-shadow: rgba(97, 97, 110, 0.2) 0px 7px 29px 0px;
}
.custom-datatable tbody td div{
  justify-content: center;
}
.custom-datatable tr td div {
  font-weight: 400;
  color: #7b809a;
  margin: 0px;
  font-size: 0.9rem;
  letter-spacing: 0px;
}
.custom-datatable th,
.custom-datatable td{
  padding: 2px !important;
  text-align: center !important;
}
.custom-datatable th span{
  justify-content: center;
}
.custom-datatable th div {
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px;
  color: #344767;
  letter-spacing: 0px;
  padding: 0px;
  margin: 0px;
}
.custom-datatable tfoot tr td{
  border: none;
}
.custom-datatable svg{
  color: #7b809a;
  font-size: 22px;
}