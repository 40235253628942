/* .kid-future-main {
  margin-top: -10rem !important;
} */
.kids-future-bar-chart {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  width: 100%;
}
.kids-future-bars {
  display: inline-flex;
}
.Kids-future-headline {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background-color: #126dba;
  padding: 5px;
  width: 200px;
  margin: auto;
  text-align: center;
}
.kids-future-bar-chart span {
  display: none !important;
  /* font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0px !important;
  color: rgb(52, 71, 103); */
}

.kids-future-list-btn {
  padding: 10px 15px !important;
  width: max-content;
}

@media only screen and (max-width: 991px) {
  .kid-future-main {
    margin-top: 0 !important;
  }
  .kids-future-bar-chart {
    width: 100%;
  }
}
