.section.call-back {
  padding: 25px;
  display: flex;
}

.section.call-back .call-back-box {
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
}
@media only screen and (max-width: 991px) {
  .section.call-back .call-back-box {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .section.call-back .call-back-box {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .cta-section .cta-wrapper {
    width: 80% !important;
  }
}
.call-back-title {
  font-size: 24px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
  text-align: center;
  /* margin-top: 20px !important; */
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* call-to-action */
.cta-section {
  padding: 36px 15px;
  width: 100%;
  background-size: cover;
  height: 350px;
  box-sizing: border-box;
  display: flex;
}
.cta-section .cta-wrapper {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
}
.cta-section .cta-wrapper .cta-title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 28px;
  line-height: 1.3;
  margin-bottom: 20px;
}
.cta-section .cta-wrapper .cta-desc {
  font-size: 19px;
  line-height: 1.3;
  padding-bottom: 30px;
}
.cta-section .cta-wrapper .effect-btn {
  width: fit-content;
}
