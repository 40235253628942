.course-card-custom {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
}
.our-course-main div img {
  /* width: 200px; */
  object-fit: none !important;
}
.our-course-heading {
  height: 25% !important;
  width: 85%;
}

@media only screen and (max-width: 1200px) {
  .course-card-custom {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 991px) {
  .course-card-custom {
    grid-template-columns: repeat(2, minmax(0, 2fr));
    margin: 0 2rem;
  }
}
@media only screen and (min-width: 751px) and (max-width: 991px) {
  .course-card-custom .last {
    width: 425px;
    margin: 10% 42%;
  }
}

@media only screen and (max-width: 750px) {
  .course-card-custom {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 0 -0.5rem 0 2rem;
  }
  .our-course-main {
    width: 90%;
  }
  .our-course-heading {
    height: 23% !important;
    width: 82%;
    padding: 15px 18px !important;
  }
}
