.description,
.custom-form-label {
  line-height: 1.5;
  letter-spacing: 0.02857em;
}
.description {
  font-weight: 400;
  color: #7b809a;
  margin: 0px;
  font-size: 0.875rem;
  line-height: 30px;
}

.heading {
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  margin: 0px !important;
  color: rgb(52, 71, 103);
}
.testing span {
  color: #ff8d1a;
  font-weight: 600;
  font-size: 13px;
}
.testing,
.testing-icon {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testing-icon {
  margin-right: 0.5rem;
}
/* custom input filed */
.custom-input-filed {
  outline: none !important;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  letter-spacing: 0px;
  border: 1px solid #ced4da;
  color: #344767;
  font-size: 15px;
  padding: 6px 15px;
}
/* custom form group */
.custom-form-group {
  margin-bottom: 10px;
}
.custom-form-label {
  color: #685f78;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  margin-bottom: 0.5rem !important;
}
.communication-setting-wrapper {
  margin: 1rem 0;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
}
.section-bg {
  background: rgb(51, 111, 148);
  background: radial-gradient(
    circle,
    rgba(51, 111, 148, 1) 0%,
    rgb(8, 53, 89) 100%
  );
}

.offer-bg {
  padding: 20rem 0;
  background: url("./../image/offer-bg-2.png");
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  /* z-index: 1 !important; */
}
@media only screen and (min-width: 1200px) {
  .slider-bg {
    margin-top: -5rem;
    background: url("./../image/slider-bg.png");
    background-position: 50% bottom;
    background-size: cover;
    background-repeat: repeat-x;
  }

  .highlight-section {
    background: url("./../image/course-bg.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    /* max-width: 1600px; */
  }
}

@media only screen and (max-width: 991px) {
  .section {
    padding: 40px 0;
  }
  .offer-bg {
    /* padding: 7rem 0 3rem 0; */
    background: url("./../image/offer-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .slider-bg {
    background: url("./../image/slider-bg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .management-section {
    background-position: center !important;
  }
}
/* @media only screen and (max-width: 1200px) {
  .offer-bg {
    padding: 12rem 0;
    background: url("./../image/offer-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
} */
.slider-bg .section-title {
  display: none;
}
.management-section {
  background: url("./../image/memory-power-bg.png");
  background-position: left inherit !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.inner-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.grid-section {
  background: url("./../image/grid-bg.svg");
}
.highlight-section {
  /* background-color: #f5f5f5; */
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #000;
}
.section-bg .section-title h2 {
  color: #ffffff;
}
.highlight-section .section-title h2 {
  color: #344767;
  font-size: 40px;
}
.highlight-section .section-title h2::before,
.highlight-section .section-title h2::after {
  /* content: "★"; */
  content: "";
  width: 200px;
  height: 2px;
  background: #344767;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .section {
    padding: 30px 0 !important;
  }
  .margin-wrapper {
    margin: 0 1.4rem !important;
  }
  .highlight-section .section-title h2 {
    font-size: 32px;
  }
  .highlight-section .section-title h2::before,
  .highlight-section .section-title h2::after {
    width: 50px;
  }
}

.highlight-section .section-title h2::before {
  margin: 0 15px 10px 0;
}

.highlight-section .section-title h2::after {
  margin: 0 0 10px 15px;
}
