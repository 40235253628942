.MuiContainer-root {
  padding: 0 !important;
}

.icon {
  background-image: linear-gradient(
    0deg,
    rgb(106, 115, 210, 0.8),
    rgb(106, 115, 210, 0.8)
  );
  height: 80px;
  width: 80px;
  border-radius: 50%;
  padding: 25px;
}
.learning-bg-custom {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 500px;
  width: 100%;
  border-radius: 44px;
}
.custom-learn-topic {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
}
@media only screen and (max-width: 991px) {
  .learning-bg-custom {
    background-size: cover;
    height: 450px !important;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .custom-learn-topic {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .learning-bg-custom {
    background-size: cover;
    height: 700px !important;
    text-align: center;
  }
  .custom-learn-topic :nth-child(5) {
    padding-top: 1.5rem;
    width: 100vw;
  }
}
