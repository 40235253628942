/* effect btn */
.btn {
  width: max-content !important;
}
.effect-btn {
  animation: f 53s linear infinite;
  background-color: #f993d2;
  align-items: center;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  padding: 0.65rem 2rem;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  display: flex;
  justify-content: center;
}
.effect-btn svg {
  font-size: 20px;
}
@keyframes btn {
  0% {
    background-position: -5000px 0;
  }
  100% {
    background-position: 5000px 0;
  }
}
@keyframes f {
  0% {
    background-position: -5000px 0;
  }
  100% {
    background-position: 5000px 0;
  }
}
/* contained btn */
.contained-btn {
  color: #fff !important;
  background-color: #126dba !important;
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  padding: 0.5rem 1rem !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
.custom-contained-btn {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  padding: 0.5rem 1rem !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
/* callback contained btn */
.callback-contained-btn {
  text-decoration: none;
  color: #f993d2 !important;
  background-color: #ffffff !important;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  padding: 0.5rem 3rem !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
/* outlined btn */
.outlined-btn {
  color: #126dba !important;
  border-radius: 0px !important;
  border: 3px solid !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  /* border: none !important; */
  padding: 10px 15px !important;
  font-size: 13px !important;
  font-weight: 900 !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
}
.outlined-btn:hover {
  background-color: transparent !important;
}
.hover-underline-animation,
.no-border-outlined-btn span:first-of-type {
  /* display: inline-block; */
  position: relative;
  /* color: #0087ca; */
}

.hover-underline-animation::after,
.no-border-outlined-btn span:first-of-type:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0) scaleY(-2);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom left;
  transition: transform 0.3s ease-out;
}

.hover-underline-animation:hover::after,
.no-border-outlined-btn span:first-of-type:hover:after {
  transform: scaleX(1) scaleY(-2);
  transform-origin: bottom left;
}
.no-border-outlined-btn {
  /* background-color: #fff !important; */
  background-color: transparent;
  color: #126dba !important;
  border-radius: 10px;
  border: none !important;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px !important; */
  padding: 5px 20px !important;
  font-size: 15px !important;
  font-weight: 900 !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
}
.no-border-outlined-btn:hover {
  background-color: transparent !important;
}
.no-border-outlined-btn span:first-of-type {
  font-size: 15px;
  margin-left: 0.3rem;
  /* transition: transform 0.3s ease-out; */
}
.outlined-btn span {
  color: #126dba !important;
  letter-spacing: 0px !important;
}
/* contained btn */
.contained-button:hover {
  /* background-color: transparent !important; */
}
.contained-btn-wrapper .btn-desc {
  color: #126dba;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  /* margin-left: 0.6rem; */
  margin-top: 8px;
  cursor: pointer;
}
/* rounded contained btn */
.rounded-contained-btn {
  animation: f 53s linear infinite !important;
  background: linear-gradient(
    121.19deg,
    rgba(133, 239, 49, 0) 25.73%,
    hsla(0, 0%, 100%, 0.3) 45.27%,
    rgba(133, 239, 49, 0) 62.27%
  );
  background-color: #ff8d1a !important;
  color: #fff !important;
  background-color: #ff8d1a !important;
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  padding: 0.5rem 1rem !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 30px !important;
  text-decoration: none !important;
  display: inherit;
  width: 90% !important;
  text-align: center;
}
.custom-btn.active {
  background: rgb(90, 113, 132) !important;
  color: #f7f7f7 !important;
}
.custom-btn {
  border-radius: 999px !important;
  background: rgb(147, 157, 165) !important;
  color: #f7f7f7 !important;
  box-shadow: 0px 0px 10px #ccc !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  outline: 0 solid transparent !important;
  padding: 8px 18px !important;
  border: 0 !important;
}
.header-submenu {
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  border-bottom: 0.5px solid #f2f2f2 !important;
  margin: 0px !important;
  color: rgb(52, 71, 103);
  text-transform: capitalize !important;
}
.header-submenu:hover {
  background: #389ff940 !important;
}
.header-submenu svg {
  background: #ffffff;
  padding: 2px;
  border-radius: 5px;
  font-size: 25px !important;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px !important;
}
