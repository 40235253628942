body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Poppins", sans-serif;
}

html,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
i,
a,
button,
input,
label,
li {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif !important;
  letter-spacing: 1px;
}
